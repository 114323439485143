<template>
  <div>
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/_partials/Navbar";
import { mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    Navbar,
  },

  async mounted() {
    document.body.style.setProperty('min-height', `${this.$screen.height}px`);
    await this.getStacks();
  },

  methods: {
    ...mapActions('app', ['getStacks']),
  },
}
</script>

<style>
html, body {
  background-color: #eff1f4 !important;
  color: #48465b !important;
}

/* Show it is fixed to the top */
body {
  /*min-height: 75rem;*/
  padding-top: 8rem;
}
</style>
