<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img :src="logoImg" alt="Hiring & Dealing" height="40">
      </a>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarCollapse" style="">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a href="https://www.hiringanddealing.com" class="nav-link">Website</a>
          </li>
          <router-link :to="{name: 'index'}" tag="li" class="nav-item">
            <a class="nav-link">Positions</a>
          </router-link>
        </ul>
        <div class="d-flex justify-content-end align-items-center fst-italic">
          <i class="fas fa-tools me-2"></i>
          In development...
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',

  computed: {
    logoImg() {
      return require('@/assets/img/logo/white_bg_horizontal.png');
    }
  },
}
</script>