<template>
  <div class="d-flex text--secondary positions-type-text position-detail-types">
    <div class="d-inline-flex justify-content-start align-items-center basis-15">
      <i :class="[employmentType(positionType.type_of_employment), 'me-2']" />
      {{ capitalize(positionType.type_of_employment) }}
    </div>
    <div class="d-inline-flex justify-content-start align-items-center basis-15">
      <i class="fas fa-clock me-2" />
      {{ capitalize(positionType.work_schedule) }}
    </div>
    <div class="d-inline-flex justify-content-start align-items-center basis-15">
      <i class="fas fa-dollar-sign me-2" />
      {{ positionType.budget_min ? `${positionType.budget_min} - ${positionType.budget_max}` : positionType.budget_max }}
    </div>
    <div v-if="positionType.stack" class="d-inline-flex justify-content-start align-items-center basis-15">
      <i class="fas fa-cogs me-2" />
      {{ positionType.stack }}
    </div>
    <div class="d-inline-flex justify-content-start align-items-center basis-40">
      <i class="fas fa-map-marked-alt me-2"></i>
      {{ capitalize(positionType.location) }}
    </div>
  </div>
</template>

<script>
import Mixins from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'PositionDetailTypes',

  mixins: [Mixins],

  props: {
    positionType: {
      type: Object,
      default: {},
    }
  },

  computed: {
    ...mapGetters('app', ['getStacks']),
  },

  methods: {
    //todo... deprecated method...
    parseStack(stackId) {
      if (this.getStacks && this.getStacks.length) {
        return this.getStacks.filter(item => item.id === stackId)[0].name;
      }

      return '---'
    },
  },
}
</script>