<template>
  <div class="row">
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 order-lg-0 order-md-1 order-sm-1 order-xs-1">
      <template v-if="loading">
        <div class="spinner-border text-secondary spinner-3x" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </template>
      <template v-if="!loading">
        <div v-if="posts && posts.length">
          <div class="d-flex justify-start align-items-center">
            <InfoCard />
          </div>
          <PositionCard
              v-for="(position, i) in posts"
              :key="i"
              :position="position"
              @linkPosition="linkPosition"
          />

          <template v-if="meta">
            <div class="d-flex justify-content-center align-items-center mt-3">
              <PositionPaginator :meta="meta" @navigate="navigatePage"/>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 order-lg-1 order-md-0 order-sm-0 order-xs-0">
      <div class="w-100 d-block d-lg-none my-2">
        <el-button
            size="mini"
            type="info"
            class="btn btn-plain-secondary w-100"
            @click="toggleFilterBtnState"
        >
          Filters...
        </el-button>
      </div>

      <div class="filters-col">
        <el-card v-if="filterBtnStateFlag || $screen.width > 900" class="el-thin-shadow my-2 bg-sm-lighter">
          <el-row>
            <el-col :span="12">
              <h4 class="pb-3">Filters</h4>
            </el-col>
            <el-col :span="12" class="text-right">
              <el-button
                  size="small"
                  class="btn btn-sm btn-plain-grey"
                  :disabled="!filterBtnState"
                  @click="clearFilter"
              >
                <u>Clear filters</u>
              </el-button>
            </el-col>
          </el-row>

          <el-row class="mb-3">
            <el-col :span="24" class="mb-2">
              <div><b class="pb-2 text--secondary">Schedule type</b></div>
              <el-radio-group v-model="filters.scheduleType" size="mini" class="position-filter-btns" @change="fetchPosts">
                <el-radio-button class="me-0" label="Full time" border></el-radio-button>
                <el-radio-button class="me-0" label="Part time" border></el-radio-button>
              </el-radio-group>

              <el-button
                  class="ms-2"
                  type="danger"
                  size="mini"
                  icon="el-icon-close"
                  circle
                  plain
                  @click="resetFilter('scheduleType')"
              >
              </el-button>
            </el-col>
          </el-row>

          <el-row class="mb-3">
            <el-col :span="24">
              <div><b class="pb-2 text--secondary">Work type</b></div>
              <el-radio-group v-model="filters.workType" size="mini" class="position-filter-btns" @change="fetchPosts">
                <el-radio-button class="me-0 mb-1" label="Remote" border></el-radio-button>
                <el-radio-button class="me-0 mb-1" label="Relocate" border></el-radio-button>
                <el-radio-button class="me-0 mb-1" label="Office" border></el-radio-button>
                <el-radio-button class="me-0 mb-1" label="Freelance" border></el-radio-button>
                <el-button
                    class="ms-2"
                    type="danger"
                    size="mini"
                    icon="el-icon-close"
                    circle
                    plain
                    @click="resetFilter('workType')"
                >
                </el-button>
              </el-radio-group>
            </el-col>
          </el-row>

          <!--          <el-row class="mb-3">
                      <el-col :span="24">
                        <div><b class="pb-2 text&#45;&#45;secondary">Budget range</b></div>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-input v-model.lazy="filters.budgetMin" size="mini" placeholder="Budget min...">
                              <span slot="prepend">Min $</span>
                            </el-input>
                          </el-col>
                          <el-col :span="12">
                            <el-input v-model.lazy="filters.budgetMax" size="mini" placeholder="Budget max...">
                              <span slot="prepend">Max $</span>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>-->

          <el-row class="mb-3">
            <el-col :span="24">
              <div><b class="ps-1 pb-2 text--secondary">Location</b></div>
              <el-input v-model.lazy="filters.location" placeholder="Location...">
                <span slot="prepend">
                  <i class="fas fa-globe-europe mx-2"></i>
                </span>

                <span slot="append" class="px-2">
                  <i
                      :class="['fas fa-times clear-btn', this.filters.location.length ? 'cursor-pointer' : 'disabled']"
                      @click="resetFilter('location')"
                  ></i>
                </span>
              </el-input>
            </el-col>
          </el-row>

          <template v-if="getStacks && getStacks.length">
            <el-row>
              <el-col :span="24">
                <div><b class="ps-1 pb-2 text--secondary">Position</b></div>
                <el-select
                    v-model="filters.stack"
                    placeholder="Choose position"
                    class="w-100"
                    clearable
                >
                  <el-option
                      v-for="item in getStacks"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </template>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@/mixins';
import PositionDetailTypes from '@/components/_partials/PositionDetailTypes';
import PositionPaginator from '@/components/_partials/PositionPaginator';
import PositionCard from '@/components/_partials/PositionCard';
import InfoCard from '@/components/_partials/InfoCard';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'Content',

  mixins: [Mixins],

  components: {
    PositionDetailTypes,
    PositionPaginator,
    PositionCard,
    InfoCard,
  },

  data() {
    return {
      posts: null,
      meta: null,
      loading: false,
      filterBtnStateFlag: false,
      search: '',
      filters: {
        workType: '',
        scheduleType: '',
        budgetMin: '',
        budgetMax: '',
        location: '',
        stack: null,
      },
    }
  },

  computed: {
    filterBtnState() {
      return Object.values(this.filters).filter(item => item && item.length > 0).length > 0 || false;
    },

    ...mapGetters('app', [
      'getPositionsFilter',
      'getStacks',
    ])
  },

  watch: {
    'filters': {
      handler: function (val) {
        this.setFiltersValue();
      },
      deep: true,
    },

    'filters.location': function (val) {
      if (val && val.length > 3) this.fetchPosts();
    },

    'filters.budgetMin': function (val) {
      if (val && val.length >= 2) this.fetchPosts();
    },

    'filters.budgetMax': function (val) {
      if (val && val.length >= 2) this.fetchPosts();
    },

    'filters.stack': function (val) {
      if (val) this.fetchPosts();
    },
  },

  async mounted() {
    this.filters = this.getPositionsFilter;
    await this.fetchPosts();
  },

  methods: {
    ...mapActions('app', [
      'setFilters'
    ]),

    async fetchPosts() {
      let page = this.$route.query.page
          ? this.$route.query.page
          : 1;
      this.loading = true;
      await this.$axios.get(`/api/positions/list?page=${page}`, {
        params: {
          ...this.filters.scheduleType && {work_schedule: this.filters.scheduleType.toLowerCase()},
          ...this.filters.workType && {type_of_employment: this.filters.workType.toLowerCase()},
          ...this.filters.budgetMin && {budget_min: this.filters.budgetMin.toLowerCase()},
          ...this.filters.budgetMax && {budget_max: this.filters.budgetMax.toLowerCase()},
          ...this.filters.location && {location: this.filters.location.toLowerCase()},
          ...this.filters.stack && {stack: this.filters.stack.toLowerCase()},
        }
      })
          .then((res) => {
            this.posts = res.data.data.data;
            this.meta = res.data.data.meta;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },

    linkPosition(id) {
      this.$router.push({name: 'position', params: {id: id}})
    },

    async navigatePage() {
      await this.fetchPosts();
    },

    clearFilter() {
      this.filters = {
        workType: '',
        scheduleType: '',
        budgetMin: null,
        budgetMax: null,
        location: '',
      };

      this.fetchPosts();
    },

    resetFilter(type) {
      if (type === 'location' && !this.filters[type].length) return;
      this.filters[type] = '';
      this.fetchPosts();
    },

    async setFiltersValue() {
      await this.setFilters({
        filters: this.filters,
      });
    },

    toggleFilterBtnState() {
      if (this.filterBtnStateFlag) {
        this.filterBtnStateFlag = false;
      } else {
        this.filterBtnStateFlag = true;
      }
    },
  },
}
</script>

<style>
.el-thin-shadow {
  box-shadow: 0 1px 2px rgb(9 30 66 / 25%) !important;
}

.el-shadow {
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%) !important;
}

.text--lightGreen {
  color: #37e798 !important;
}

.font-16 {
  font-size: 16px;
}

.border-radius-circle {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.positions-type-text {
  font-size: 14px;
}
</style>