<template>
  <main class="container d-flex justify-content-center align-items-center">
    <div class="mt-5 pt-5">
      <h1>
        404 :(
      </h1>
    </div>
  </main>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>