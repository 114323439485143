import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    name: 'index',
    path: '/',
    component: require('@/components/Home').default,
  },
  {
    name: 'position',
    path: '/position/:id',
    component: require('@/components/Position').default,
  },
  {
    path: '*',
    component: require('@/components/PageNotFound').default,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;