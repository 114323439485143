<template>
  <div class="info-card alert alert-primary w-100 px-2 pt-half fs-14" role="alert">
    If you are interested, send your resume to <a class="text-primary mail-link" href="mailto:team@hiringanddealing.com" target="_blank">team@hiringanddealing.com</a>.
  </div>
</template>

<script>
export default {
  name: 'InfoCard'
}
</script>

<style scoped>
a, a:link, a:visited, a:active {
  text-decoration: none;
  transition: all .1s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

.mail-link {
  color: #0d6efd;
  font-weight: bold;
}

.fs-14 {
  font-size: 14px;
}

.pt-half {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>