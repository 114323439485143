<template>
  <div class="row">
    <div class="col-lg-8 offset-lg-2 col-md-12 offset-md-0 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0">
      <div class="d-inline-flex w-25 xs-w-100 my-xs-2">
        <button class="btn btn-sm btn-plain-secondary px-5 xs-w-100" @click="$router.go(-1)">
          <i class="fas fa-arrow-left me-2"></i>
          Back
        </button>
      </div>

      <div class="d-inline-flex w-75 xs-w-100">
        <InfoCard />
      </div>
    </div>
    <template v-if="loading">
      <div class="col-8 offset-2 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0">
        <div class="d-flex justify-content-center align-items-center vh-100">
          <div class="spinner-border text-secondary spinner-3x" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </template>

    <template v-if="!loading">
      <template v-if="data">
        <div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12 offset-sm-0 col-xs-12 offset-xs-0">
          <div class="mt-3">
            <div class="card el-thin-shadow mb-2">
              <div class="card-body">
                <h5 class="card-title cursor-pointer">
                  <i class="fas fa-circle font-16 el-shadow border-radius-circle text--lightGreen me-2"></i>
                  {{ data.title }}
                </h5>
                <PositionDetailTypes :position-type="data"/>
              </div>
              <div class="card-body card-description">
                <div v-html="data.description" />
                <div class="d-flex justify-content-end align-items-center social-links text--secondary">
                  <b class="me-2">Social links:</b>
                  <i class="fab fa-facebook me-2 cursor-pointer text-primary" @click="shareLinkFb"></i>
                  <i class="fab fa-twitter me-2 cursor-pointer text-info" @click="shareLinkTw"></i>
                  <i class="fab fa-linkedin me-2 cursor-pointer text-menuIndigo" @click="shareLinkInk"></i>
                  <i class="fab fa-whatsapp-square me-2 cursor-pointer text-green" @click="shareLinkWapp"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="errorMsg">
          <div class="col-8 offset-2">
            <div class="d-flex justify-content-center align-items-center mt-5 pt-5">
              <h4 class="text--secondary fst-italic">{{ errorMsg }}...</h4>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import PositionDetailTypes from '@/components/_partials/PositionDetailTypes';
import InfoCard from '@/components/_partials/InfoCard';

export default {
  name: 'PositionDetails',

  components: {PositionDetailTypes, InfoCard},

  data() {
    return {
      data: null,
      loading: false,
      errorMsg: null,
    }
  },

  async mounted() {
    this.errorMsg = null;
    await this.fetchPosition();
  },

  methods: {
    async fetchPosition() {
      this.loading = true;
      await this.$axios.get(`/api/positions/${this.$route.params.id}/view`)
          .then((res) => {
            this.loading = false;
            this.data = res.data.data;
            if (!res.data.success) this.errorMsg = res.data.message;
          })
          .catch(() => {
            this.loading = false;
          });
    },

    shareLinkFb() {
      window.open(`https://www.facebook.com/sharer/sharer.php?href=${document.URL}`, '_blank');
    },

    shareLinkInk() {
      //window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${document.URL}`, '_blank');
      window.open(`https://www.linkedin.com/shareArticle?url=${document.URL}`, '_blank');
    },

    shareLinkTw() {
      window.open(`https://twitter.com/intent/tweet?url=${document.URL}`, '_blank');
    },

    shareLinkWapp() {
      window.open(`whatsapp://send?text=${document.URL}`, '_blank');
    },
  },
}
</script>

<style>
.text-green {
  color: #38c172;
}

.text-menuIndigo {
  color: #4040c8;
}
</style>
