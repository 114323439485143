import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

import {
  Button,
  Radio,
  RadioGroup,
  RadioButton,
  Col,
  Row,
  Card,
  Input,
  Select,
  Option,
  Alert,
} from 'element-ui';
Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Col);
Vue.use(Row);
Vue.use(Card);
Vue.use(Input);
Vue.use(RadioButton);
Vue.use(Select);
Vue.use(Option);
Vue.use(Alert);

import 'element-ui/lib/theme-chalk/index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import '@/assets/style.css';

import axios from 'axios';

import store from '@/store/store';
import router from '@/router';

axios.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

Vue.prototype.$screen = {width: window.screen.width, height: window.screen.height};
Vue.prototype.$axios = axios;

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
