<template>
  <main class="container">
    <PositionDetails />
  </main>
</template>

<script>
import PositionDetails from '@/components/_partials/PositionDetails';

export default {
  name: 'Position',

  components: {
    PositionDetails,
  },
}
</script>