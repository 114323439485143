<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <!-- Prev arrow btn -->
      <li :class="['page-item ', meta.current_page === 1 ? 'disabled' : undefined]">
        <a class="page-link" @click="navigate(meta.current_page - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>

      <!-- Pagination links -->
      <li
          v-for="(page, i) in meta.last_page"
          :key="i"
          :class="['page-item ', meta.current_page === page ? 'active cursor-default' : undefined]"
      >
        <a @click="navigate(page)" class="page-link cursor-pointer">{{ page }}</a>
      </li>

      <!-- Last arrow btn -->
      <li :class="['page-item ', meta.last_page === meta.current_page ? 'disabled' : undefined]">
        <a class="page-link" @click="navigate(meta.current_page + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'PositionPaginator',

  props: {
    meta: {
      type: [Object, Array],
      default: {},
    },

    routeName: {
      type: String,
      default: '',
    }
  },

  methods: {
    navigate(page) {
      if (this.meta.current_page === page) return;
      this.$router.push({
        name: this.routeName,
        query: {
          page: page
        }
      });
      this.$emit('navigate', Number(page));
    },
  },
}
</script>