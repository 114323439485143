import { storeState } from "@/utils/constants";
import { getStorageItem, setStorageItem } from "@/utils/storage";
import axios from 'axios';

const { app } = storeState

export default {
  namespaced: true,

  state: {
    stacks: {},
    positionsFilter: {
      workType: getStorageItem(app.filters, 'workType'),
      scheduleType: getStorageItem(app.filters, 'scheduleType'),
      budgetMin: getStorageItem(app.filters, 'budgetMin'),
      budgetMax: getStorageItem(app.filters, 'budgetMax'),
      location: getStorageItem(app.filters, 'location'),
      stack: getStorageItem(app.filters, 'stack'),
    },
  },

  getters: {
    getPositionsFilter: (state) => {
      return state.positionsFilter;
    },

    getStacks(state) {
      return state.stacks;
    },
  },

  mutations: {
    setPositionsFilter(state, payload) {
      const { filters } = payload;
      state.positionsFilter = filters;
      setStorageItem(app.filters, filters);
    },

    setStacks(state, payload) {
      state.stacks = payload;
    },
  },

  actions: {
    setFilters({commit}, payload) {
      commit('setPositionsFilter', payload);
    },

    getStacks({commit}, payload) {

      return new Promise((resolve, reject) => {
        axios.get('/api/positions/stacks')
          .then((res) => {
            resolve(res);
            commit('setStacks', res.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
}