import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);
import app from './modules/app';

const store = new Vuex.Store({
  modules: {
    app,
  },
});

export default store;