export default {
  methods: {
    employmentType(type) {
      if (type === null || typeof type === 'undefined') return;

      if (type.toLowerCase() === 'relocate' || type.toLowerCase() === 'office') {
        return 'fas fa-globe-europe';
      } else {
        return 'fas fa-map-marker-alt';
      }
    },

    capitalize(str) {
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    },
  },
}