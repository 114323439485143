<template>
  <main class="container justify-content-center align-items-center">
    <Content />
  </main>
</template>

<script>
import Content from "@/components/_partials/Content";

export default {
  name: 'Home',

  components: {
    Content,
  },

  methods: {
  },
}
</script>