<template>
  <div v-if="position" class="card el-thin-shadow mb-2">
    <div class="card-body">
      <h5 class="card-title cursor-pointer position-link" @click="linkPosition(position.id)">
        <i class="fas fa-circle font-16 el-shadow border-radius-circle text--lightGreen me-2"></i>
        {{ capitalize(position.title) }}
      </h5>
      <PositionDetailTypes :position-type="position" />
    </div>
    <div class="card-body card-description">
      <div v-html="`${position.description.substring(0, 455)}...`" />
      <div class="pt-3">
        <button class="btn btn-sm btn-plain-secondary px-3" @click="linkPosition(position.id)">
          View details
          <i class="fas fa-chevron-double-right ms-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PositionDetailTypes from '@/components/_partials/PositionDetailTypes';
import Mixins from '@/mixins';

export default {
  name: 'PositionCard',

  components: {PositionDetailTypes},

  mixins: [Mixins],

  props: {
    position: {
      type: Object,
      default: {},
    }
  },

  methods: {
    linkPosition(id) {
      this.$emit('linkPosition', id);
    },
  },
}
</script>